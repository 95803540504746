import { FC, useMemo } from 'react'
import { topNavData } from './topNavData'
import { useNavigationRoutes } from '../../../../hooks/useNavigationRoutes'
import dynamic from 'next/dynamic'

const MarketingTopNav = dynamic(() =>
  import('../../../TopNav/v2/MarketingTopNav').then(mod => mod.MarketingTopNav)
)

const PlatformTopNav = dynamic(() =>
  import('../../../TopNav/v2/PlatformTopNav').then(mod => mod.PlatformTopNav)
)

interface FCNavProps {
  hasScrolled: boolean
  referer?: string | null
}

export const NavigationV2: FC<FCNavProps> = ({ hasScrolled }: FCNavProps): JSX.Element | null => {
  const {
    isFlowpageRoute,
    shouldShowPlatformTopNav,
    currentPageIsPlatformRoute,
    isAuthChecked,
    isAuthenticated
  } = useNavigationRoutes()

  const resolvedShowPlatformNav = useMemo(
    () => shouldShowPlatformTopNav && currentPageIsPlatformRoute,
    [shouldShowPlatformTopNav, currentPageIsPlatformRoute]
  )

  return (
    <>
      {isAuthChecked && resolvedShowPlatformNav && <PlatformTopNav hasScrolled={hasScrolled} />}
      {!resolvedShowPlatformNav && (
        <MarketingTopNav
          isPositionFixed={true}
          isFlowpageRoute={isFlowpageRoute}
          isLoggedIn={isAuthenticated}
          topNavPrismic={topNavData}
        />
      )}
    </>
  )
}
