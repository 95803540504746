import { DialogClose } from './DialogClose'
import { DialogTransition } from './DialogTransition/DialogTransition'
import { JSXElementConstructor, forwardRef, useId } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { useDialogTransitionContext } from './DialogTransition/useDialogTransitionContext'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'

/**
 * Provides a Dialog for overlaying complex form elements like forms without the user
 * losing their context.
 *
 * To support a Dialog being opened from another Dialog, include the
 * DialogTransitionProvider.
 *
 * Documentation: [https://mui.com/material-ui/api/dialog-title]
 *
 * @see DialogTransition
 * @see DialogTransitionProvider
 */

export type DialogProps = Pick<
  MuiDialogProps,
  | 'open'
  | 'aria-describedby'
  | 'aria-labelledby'
  | 'disableEscapeKeyDown'
  | 'fullScreen'
  | 'fullWidth'
  | 'hideBackdrop'
  | 'keepMounted'
  | 'maxWidth'
  | 'onClose'
  | 'scroll'
  | 'id'
  | 'key'
  | 'children'
  | 'ref'
  | 'PaperProps'
> & {
  showCloseIcon?: boolean
}

export const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref): JSX.Element => {
  const uuid = useId()
  const dialogTransitionContext = useDialogTransitionContext()
  const bottom = dialogTransitionContext?.at(0)
  const hideBackdrop =
    props.hideBackdrop !== undefined ? props.hideBackdrop : Boolean(bottom && bottom.id !== uuid)
  const { children, showCloseIcon, PaperProps, ...rest } = props
  return (
    <MuiDialog
      {...rest}
      ref={ref}
      hideBackdrop={hideBackdrop}
      PaperProps={{
        ...PaperProps,
        className: showCloseIcon ? 'paperWithCloseIcon' : '',
        sx: {
          pt: showCloseIcon ? 8 : 1,
          ...(PaperProps?.sx || {})
        }
      }}
      // I don't see generic or any other way to let the Dialog
      // component know that this transition takes custom props
      // except to pass them and re-cast things to appease TS
      TransitionComponent={
        dialogTransitionContext
          ? (DialogTransition as unknown as JSXElementConstructor<TransitionProps>)
          : undefined
      }
      TransitionProps={
        dialogTransitionContext
          ? ({
              uuid
            } as TransitionProps)
          : undefined
      }
    >
      {showCloseIcon && <DialogClose onClose={props.onClose} />}
      {children}
    </MuiDialog>
  )
})

Dialog.displayName = 'Dialog'
