// TODO:
// - auto-complete colors
// - support numbers for props like padding, convert to px
// - generally try to match how react works
// I looked at a half dozen types but couldn't find anything that was exposed that
// worked quite as nicely as react - and since these are low-level funcs it's
// fine to be a little cumbersome for now
export type ElementStyle = Partial<{ [key in keyof CSSStyleDeclaration]: string }>

function toHyphenatedKey(key: string): string {
  const hyphenated = key.replace(/([A-Z])/g, '-$1').toLowerCase()
  if (/^(?:webkit|moz|ms|o)-/.test(hyphenated)) {
    return `-${hyphenated}`
  }
  return hyphenated
}

/**
 * Allows setting an element's style properties directly.
 *
 * For use with complex css transitions when low-level DOM access and timing are important,
 * such as during transitions like the DialogTransition coordinating the css transitions
 * of 2 dialog elements simultaneously.
 */
export function setElementStyle(element: HTMLElement, style: ElementStyle): void {
  Object.keys(style).forEach(key => {
    const value = style[key as keyof CSSStyleDeclaration]
    if (value !== undefined) {
      element.style.setProperty(toHyphenatedKey(key), String(value))
    }
  })
}
