import { DialogTransitionContext } from './DialogTransitionContext'
import { DialogTransitionContextProps } from './DialogTransitionContextProps'
import { useContext } from 'react'

/**
 * Provides access to the context for managing dialog transitions.
 *
 * This should only be used by the Dialog component itself and the DialogTransition in order
 * to ensure that there is only one visible dialog at a time.
 */
export function useDialogTransitionContext(): DialogTransitionContextProps | undefined {
  return useContext(DialogTransitionContext)
}
