import { FC } from 'react'
import { default as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem'

export type MenuItemProps = Pick<
  MuiMenuItemProps,
  'children' | 'key' | 'onClick' | 'selected' | 'disabled' | 'value' | 'divider' | 'sx'
>

/**
 * Subcomponent of Menu. Should be wrapped by MenuList or Menu.
 */

export const MenuItem: FC<MenuItemProps> = (props: MenuItemProps) => <MuiMenuItem {...props} />
