import { SIZES } from '@dtx-company/shared-components/src/theme/media-queries'

import { useEffect, useState } from 'react'
export interface WindowSizeType {
  width: number
  height: number
  isMobile: boolean
}

const DEFAULT_WINDOW_SIZE = SIZES.tablet - 1

// do not use this hook on the server, this may cause hydration issues
export const useWindowSize = (): WindowSizeType => {
  const [width, setWidth] = useState<number>(
    typeof window === 'object' ? window?.innerWidth : DEFAULT_WINDOW_SIZE
  )

  const [height, setHeight] = useState<number>(
    typeof window === 'object' ? window?.innerHeight : DEFAULT_WINDOW_SIZE
  )

  useEffect(() => {
    const onWindowResize = (): void => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', onWindowResize)

    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  return {
    width,
    height,
    isMobile: width < SIZES.tablet
  }
}
