import * as React from 'react'
import * as Yup from 'yup'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Tab,
  Tabs,
  TextField
} from '@dtx-company/design-system/src'
import { Box } from '@mui/system'
import { FormEventHandler, useState } from 'react'
import { InvalidLinkBanner } from '../../../../components/FlowTeams/components/AddMembersModal/InvalidLinkBanner'
import { InvitationLinkDialogContent } from '../../../../components/FlowTeams/components/InvitationLinkDialogContent'
import { OrgRoleEnum, TeamRoleEnum, TeamType } from '@dtx-company/true-common/src/types/org'
import { inviteMemberToOrg } from '../../utils/org-fetch-invitations'
import { sendErrorNotification, sendSuccessNotification } from '../../../../utils/notifications'
import { useAuthState } from '../../../../hooks/useAuthState'
import { useForm } from 'react-hook-form'
import { useInvitationLink } from '../../hooks/useInvitationLink'
import { useOnce } from '@dtx-company/true-common/src/hooks/useOnce'
import { useTeams } from '../../hooks/useOrgHooks'
import { yupResolver } from '@hookform/resolvers/yup'
import EmailIcon from '@mui/icons-material/Email'
import LinkIcon from '@mui/icons-material/Link'
import events from '@dtx-company/inter-app/src/event-tracking/events/orgAdmin'

export const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Please enter a valid email address').required('Email is required'),
  team: Yup.string(),
  role: Yup.string()
})

interface InviteMembersModalProps {
  open: boolean
  onClose: () => void
  onInvite: () => void
  inviteModalProps?: { initialTab?: InviteModalTabType }
}

export enum MemberFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  TEAM = 'teamName',
  ROLE = 'orgRole'
}

interface EmailTabContentProps {
  handleSubmit: FormEventHandler<HTMLFormElement>
  email: string
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
  emailHasError: boolean
  emailErrorMessage: string
  showTeamsInput: boolean
  allTeams: TeamType[] | undefined
  onChangeTeam: (selected: TeamType[]) => void
  selectedTeams: TeamType[]
  allRoles: {
    label: string
    value: OrgRoleEnum
  }[]
  orgRole: {
    label: string
    value: string
  }
  onChangeRole: (args: { value: string }) => void
  hasOrgRoleError: boolean
  isSubmittingForm: boolean
  orgRoleErrorMessage: string
}

const EmailTabContent: React.FC<EmailTabContentProps> = props => {
  return (
    <form style={{ width: '100%', paddingTop: '16px' }} onSubmit={props.handleSubmit}>
      <DialogContent>
        <TextField
          value={props.email}
          autoFocus
          onBlur={() => {
            if (props.email.length) events.Flowteams_Invite_Modal_Email_Tab_Typed_Email_Field()
          }}
          onChange={props.onChangeEmail}
          type="email"
          label="Email address"
          error={props.emailHasError}
          helperText={props.emailErrorMessage}
          fullWidth
        />
        <Box sx={theme => ({ my: theme.spacing(4) })}>
          {props.showTeamsInput && (
            <Autocomplete
              disablePortal={false}
              multiple
              id="search-teams"
              options={props.allTeams ?? []}
              value={props.selectedTeams}
              onChange={(_e, newValue) => props.onChangeTeam(newValue as TeamType[])}
              getOptionLabel={team => team.name}
              label="Teams"
              spellCheck={false}
              autoCorrect="off"
              onFocus={events.Flowteams_Invite_Modal_Email_Tab_Focused_Teams_Field}
            />
          )}
        </Box>
        <Select
          name="Role"
          label="Role"
          options={props.allRoles}
          value={props.orgRole.value}
          onChange={e => {
            props.onChangeRole({ value: e.target.value })
          }}
          onOpen={events.Flowteams_Invite_Modal_Email_Tab_Clicked_Role_Field}
          error={props.hasOrgRoleError}
          helperText={props.orgRoleErrorMessage}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          fullWidth
          loading={props.isSubmittingForm}
          onClick={events.Flowteams_Invite_Modal_Email_Tab_Clicked_Add_Member_Button}
        >
          Invite new member
        </Button>
      </DialogActions>
    </form>
  )
}

export type InviteModalTabType = 'link' | 'email'

const tabs: InviteModalTabType[] = ['email', 'link']

export const InviteMembersModal = ({
  open,
  onClose,
  onInvite,
  inviteModalProps
}: InviteMembersModalProps): JSX.Element => {
  const { org } = useAuthState()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const allTeamsState = useTeams({ orgId: org?.orgId, paginate: false })
  const [selectedTeams, setSelectedTeams] = React.useState<TeamType[]>([])
  const hasNoTeams = !allTeamsState?.isLoading && allTeamsState?.teams?.length === 0
  const allRoles = [
    {
      label: 'View Only',
      value: OrgRoleEnum.VIEWER,
      icon: '/icons/eye.svg'
    },
    {
      label: 'Member',
      value: OrgRoleEnum.MEMBER,
      icon: '/icons/team.svg'
    },
    {
      label: 'Admin',
      value: OrgRoleEnum.ADMIN,
      icon: '/icons/profile.svg'
    }
  ]

  const { jwt } = useAuthState()

  const onSubmit = async (data: {
    firstName: string
    lastName: string
    email: string
    orgRole: {
      label: string
      value: string
    }
  }): Promise<void> => {
    try {
      setIsSubmitting(true)
      if (org?.orgId) {
        const isInvited = await inviteMemberToOrg(jwt, {
          ...data,
          teams: selectedTeams?.map(t => ({
            teamId: t.teamId,
            teamRole: TeamRoleEnum.MEMBER
          })),
          orgId: org.orgId,
          orgRoleExtras: [],
          orgRole: data.orgRole.value as OrgRoleEnum
        })
        if (isInvited) {
          sendSuccessNotification('Invitation sent successfully')
          events.userInvitedNewMember()
          reset(defaultValues)
          setSelectedTeams([])
          onInvite()
        } else {
          onClose()
        }
      }
    } catch (error) {
      console.error('Error sending invitation ', error)
      sendErrorNotification('Error sending invitation.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const defaultValues = {
    [MemberFormFields.FIRST_NAME]: '',
    [MemberFormFields.LAST_NAME]: '',
    [MemberFormFields.EMAIL]: '',
    [MemberFormFields.ROLE]: {
      label: 'Member',
      value: OrgRoleEnum.MEMBER
    }
  }

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      Yup.object({
        firstName: Yup.string(),
        lastName: Yup.string(),
        email: Yup.string()
          .email('Please enter a valid email address')
          .required('Email is required'),
        orgRole: Yup.object({
          label: Yup.string().required(),
          value: Yup.string().required('Role is required')
        })
      })
    )
  })

  const watchAll = watch()

  React.useEffect(() => {
    register(MemberFormFields.FIRST_NAME)
    register(MemberFormFields.LAST_NAME)
    register(MemberFormFields.EMAIL)
    register(MemberFormFields.ROLE)
  }, [register])

  const [tabSelected, setTabSelected] = useState<InviteModalTabType>('email')

  const { invitationLink, error } = useInvitationLink({
    orgId: org?.orgId,
    linkType: 'org'
  })

  const showInvitationLink = Boolean(invitationLink)

  const showEmailTab = tabSelected === 'email' || !showInvitationLink

  useOnce(() => {
    if (inviteModalProps && inviteModalProps.initialTab) {
      setTabSelected(inviteModalProps.initialTab)
    }
  })

  return (
    <Dialog
      open={open}
      onClose={() => {
        reset(defaultValues)
        setSelectedTeams([])
        onClose()
      }}
    >
      <DialogTitle>Invite a new member</DialogTitle>
      {error ? (
        <DialogContent>
          <InvalidLinkBanner />
        </DialogContent>
      ) : (
        <Box
          sx={theme => ({
            minWidth: { xs: 'auto', md: theme.spacing(138) }
          })}
        >
          <Tabs
            value={tabs.indexOf(tabSelected)}
            onChange={(_, index) => setTabSelected(tabs[index])}
          >
            <Tab
              data-testid="email-tab"
              iconPosition="start"
              label="Email"
              icon={<EmailIcon />}
              onClick={events.Flowteams_Invite_Modal_Clicked_Invite_By_Email_Tab}
            />
            <Tab
              data-testid="link-tab"
              iconPosition="start"
              label="Link"
              icon={<LinkIcon />}
              onClick={events.Flowteams_Invite_Modal_Clicked_Invite_By_Link_Tab}
            />
          </Tabs>
          <Box
            sx={{
              width: '100%',
              height: '2px',
              mt: '-2px',
              backgroundColor: 'border.divider'
            }}
          />
          {showEmailTab || !showInvitationLink ? (
            <EmailTabContent
              handleSubmit={handleSubmit(onSubmit)}
              email={watchAll.email}
              onChangeEmail={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(MemberFormFields.EMAIL, e.target.value)
              }}
              emailHasError={Boolean(errors.email)}
              emailErrorMessage={errors?.email?.message ?? ''}
              showTeamsInput={!hasNoTeams}
              allTeams={allTeamsState?.teams || []}
              onChangeTeam={selected => {
                events.userSelectedTeamsWhenAddingNewMember(selected.map(s => s.name))
                setSelectedTeams(selected)
              }}
              selectedTeams={selectedTeams}
              allRoles={allRoles}
              orgRole={watchAll.orgRole}
              onChangeRole={({ value }: { value: string }) => {
                const selectedRole = allRoles.find(role => role.value === value)
                if (selectedRole?.value) {
                  setValue(MemberFormFields.ROLE, {
                    label: selectedRole?.label,
                    value: selectedRole?.value
                  })
                }
              }}
              hasOrgRoleError={Boolean(errors.orgRole)}
              orgRoleErrorMessage={errors?.orgRole?.value?.message ?? ''}
              isSubmittingForm={isSubmitting}
            />
          ) : (
            invitationLink && <InvitationLinkDialogContent invitationLink={invitationLink} />
          )}
        </Box>
      )}
    </Dialog>
  )
}
