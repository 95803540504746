import {
  MemberType,
  OrgExtrasType,
  TeamMemberType,
  TeamType
} from '@dtx-company/true-common/src/types/org'
import { addColorToTeams, getRequestHeadersWithAuthHeader } from '../utils'
import { numTeamsPerPage, numUsersPerPage, orgEndPoints } from '../constants/orgAdmin'
import { useAuthState } from '../../../hooks/useAuthState'
import useSWR, { KeyedMutator, useSWRConfig } from 'swr'

type Fetcher = (input: RequestInfo) => Promise<Record<string, any>>

export const useFetcher = (): Fetcher => {
  const { jwt } = useAuthState()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetcher = async (input: RequestInfo): Promise<Record<string, any>> => {
    return input
      ? await fetch(input, {
          method: 'GET',
          credentials: 'include',
          headers: await getRequestHeadersWithAuthHeader(jwt?.token)
        }).then(res => res.json())
      : []
  }
  return fetcher
}

export enum MembersOrderBy {
  NameAsc = 'name',
  NameDsc = '-name',
  RoleAsc = 'role',
  RoleDsc = '-role',
  LastLoginAsc = 'lastLogin',
  LastLoginDesc = '-lastLogin'
}

interface UseMembersArgs {
  orgId?: string
  limit?: number
  offset?: number
  orderBy?: MembersOrderBy
  searchTerm?: string
  paginate?: boolean
  invitationsOnly?: boolean
  teams?: boolean
}

export interface UseMembersReturnType {
  members: MemberType[] | undefined
  path?: string
  isLoading: boolean
}

export const useMembers = ({
  orgId,
  limit = numUsersPerPage,
  offset = 0,
  orderBy = MembersOrderBy.NameAsc,
  searchTerm,
  paginate = true,
  invitationsOnly = false,
  teams = false
}: UseMembersArgs): UseMembersReturnType => {
  const queryStringObject: Record<string, string> = { orderBy }
  if (invitationsOnly) {
    queryStringObject.limit = '0'
    queryStringObject.offset = '0'
  } else {
    if (paginate) {
      queryStringObject.limit = limit.toString()
      queryStringObject.offset = offset.toString()
    }
  }
  if (searchTerm) queryStringObject.searchTerm = searchTerm
  const queryString = new URLSearchParams(queryStringObject).toString()
  const resolvedQueryString = teams ? queryString + '&teams' : queryString
  const path = orgId ? `${orgEndPoints.members}/${orgId}?${resolvedQueryString}` : null
  const revalidationOptions = {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  }

  const fetcher = useFetcher()

  const { data, error, isLoading } = useSWR(path, fetcher, revalidationOptions)

  if (error || data?.status !== 200 || !orgId) {
    return { members: undefined, isLoading: false }
  }

  return {
    members: invitationsOnly ? data?.invitations || [] : data?.orgMembers || [],
    path: path || `${orgEndPoints.members}/${orgId}`,
    isLoading
  }
}

export const useOrgExtras = (orgId?: string, orgName?: string): OrgExtrasType[] => {
  const { cache } = useSWRConfig()
  const path =
    orgId && orgName?.toLowerCase().includes('flowcode') ? `${orgEndPoints.orgExtras}/${orgId}` : ''
  const revalidationOptions = {
    revalidateOnMount: cache.get(path) !== undefined,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  }

  const fetcher = useFetcher()

  const { data, error } = useSWR(path, fetcher, revalidationOptions)

  if (error || !data || data?.status !== 200 || !orgId) {
    return []
  }
  return data?.orgExtras || []
}

export enum TeamsOrderBy {
  NameAsc = 'teamName',
  NameDsc = '-teamName',
  CountAsc = 'memberCount',
  CountDsc = '-memberCount'
}

interface UseTeamsArgs {
  orgId: string | undefined
  limit?: number
  offset?: number
  orderBy?: TeamsOrderBy
  searchTerm?: string
  paginate?: boolean
  teamId?: string
  directChildren?: boolean
  me?: boolean
}

export const useTeams = ({
  orgId,
  limit = numTeamsPerPage,
  offset = 0,
  orderBy = TeamsOrderBy.NameAsc,
  searchTerm,
  paginate = true,
  teamId,
  directChildren = false,
  me = false
}: UseTeamsArgs):
  | { teams: TeamType[]; mutate: KeyedMutator<Record<string, any>>; isLoading?: boolean }
  | undefined => {
  const queryStringObject: Record<string, string> = { orderBy }
  if (paginate) {
    queryStringObject.limit = limit.toString()
    queryStringObject.offset = offset.toString()
  }
  if (searchTerm) queryStringObject.searchTerm = searchTerm
  if (teamId) queryStringObject.teamId = teamId
  let queryString = new URLSearchParams(queryStringObject).toString()
  if (directChildren) queryString = queryString + '&directChildren'
  if (me) queryString = queryString + '&me'

  const path = orgId ? `${orgEndPoints.teams}/${orgId}?${queryString}` : null
  const revalidationOptions = {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  }
  const fetcher = useFetcher()
  const { data, error, mutate, isLoading } = useSWR(path, fetcher, revalidationOptions)
  if (error || !data || data?.status !== 200 || !orgId) {
    return undefined
  }
  const teams = addColorToTeams(data.teams)
  return { teams, mutate, isLoading }
}

export const useTeamMembers = (teamId: string | undefined): TeamMemberType[] => {
  const path = teamId ? `${orgEndPoints.teamMembers}/${teamId}` : null
  const fetcher = useFetcher()
  const { data, error } = useSWR(path, fetcher)
  if (error || !data || data?.status !== 200 || !teamId) {
    return []
  }
  const teamMembers = data?.teamMembers || []

  return teamMembers
}

export const useInvitations = ({
  orgId
}: {
  ithacaId?: string
  orgId?: string
}): MemberType[] | undefined => {
  const path = orgId ? `${orgEndPoints.invitations}?orgId=${orgId}` : null
  const revalidationOptions = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true
  }
  const fetcher = useFetcher()
  const { data, error } = useSWR(path, fetcher, revalidationOptions)
  if (error || !data || data?.status !== 200) {
    return undefined
  }
  return data?.invitations
}

export const useInvitation = (
  invitationId?: string,
  token?: string
): { invitation: MemberType | undefined; orgName: string | undefined } | undefined => {
  const path =
    token && invitationId
      ? `${orgEndPoints.invitations}?invitationId=${invitationId}&token=${token}`
      : null
  const fetcher = useFetcher()
  const { data, error } = useSWR(path, fetcher)
  if (data?.status === 404) {
    return { invitation: undefined, orgName: undefined }
  }
  if (error || !data || data?.status !== 200 || !token) {
    return undefined
  }

  return { invitation: data?.invitations[0] || undefined, orgName: data?.orgName }
}
export const useGetInvitationLinkInfo = (
  invitationId?: string
): { orgName: string | undefined; teamName?: string } | undefined => {
  const path = orgEndPoints.invitationLink + '/' + invitationId
  const fetcher = useFetcher()
  const { data, error } = useSWR(path, fetcher)
  if (data?.status === 404) {
    return { orgName: undefined, teamName: undefined }
  }
  if (error || !data || data?.status !== 200) {
    return undefined
  }

  return { orgName: data?.orgName, teamName: data?.teamName }
}

export const useGetInvitationInfo = (
  invitationId?: string
):
  | {
      email: string | undefined
      orgName: string | undefined
      teamNames?: string[]
      inviter?: MemberType['inviter']
    }
  | undefined => {
  const path = orgEndPoints.getInviteDataV2 + '/' + invitationId
  const fetcher = useFetcher()
  const { data, error } = useSWR(path, fetcher)
  if (data?.status === 404) {
    return { email: undefined, orgName: undefined, teamNames: undefined, inviter: undefined }
  }
  if (error || !data || data?.status !== 200) {
    return undefined
  }
  return {
    email: data?.email,
    orgName: data?.orgName,
    teamNames: data?.teamNames,
    inviter: data?.inviter
  }
}
