interface ComponentProps {
  easing: string | { enter?: string; exit?: string } | undefined
  style: React.CSSProperties | undefined
  timeout: number | { enter?: number; exit?: number }
}

interface Options {
  mode: 'enter' | 'exit'
}

interface TransitionProps {
  duration: string | number
  easing: string | undefined
  delay: string | undefined
}

/**
 * Takes a mode (enter|exit) and calculates the css transition prop
 * with the proper duration, easing function, and delay.
 *
 * Adapted from MUI utils that aren't technically supported for importing
 * and throw an error when attempted.
 * https://github.com/mui/material-ui/blob/fe6942947bb9eb920b1784b708dbb4ea58405ce3/packages/mui-material/src/transitions/utils.ts#L21
 */
export function getTransitionProps(props: ComponentProps, options: Options): TransitionProps {
  const { timeout, easing, style = {} } = props

  return {
    duration:
      style.transitionDuration ??
      (typeof timeout === 'number' ? timeout : timeout[options.mode] || 0),
    easing:
      style.transitionTimingFunction ??
      (typeof easing === 'object' ? easing[options.mode] : easing),
    delay: style.transitionDelay
  }
}
