import { useEffect, useState } from 'react'
import { useScroll } from 'framer-motion'
import { useWindowSize } from '@app/common/src/hooks/useWindowSize'

interface UseScrollPositionReturnType {
  scrollPosition: number
  hasScrolled: boolean
  hasScrolledPastTopBanner: boolean
  hasScrolledPastFirstFullScreen: boolean
}

// TODO: Replace calls to this implementation with the one from @app/common/src/hooks AF
export function useScrollPosition(): UseScrollPositionReturnType {
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [scrolledPastFirstFullScreen, setScrolledPastFirstFullScreen] = useState<boolean>(false)
  const { scrollY } = useScroll()
  const { height } = useWindowSize()

  useEffect(() => {
    const unsubscribeY = scrollY.onChange(pos => {
      setScrollPosition(pos)
    })
    return () => unsubscribeY()
  }, [scrollY])

  useEffect(() => {
    if (!scrolledPastFirstFullScreen && scrollPosition >= height) {
      setScrolledPastFirstFullScreen(true)
    }
  }, [scrolledPastFirstFullScreen, scrollPosition, height])

  return {
    scrollPosition,
    hasScrolled: scrollPosition > 0,
    hasScrolledPastTopBanner: scrollPosition > 40,
    hasScrolledPastFirstFullScreen: scrolledPastFirstFullScreen
  }
}
