import { InviteModalTabType } from '@dtx-company/inter-app/src/components/org/members/modals/inviteModal'
import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCallback } from 'react'
import { useFlowRouter } from '@dtx-company/inter-app/src/hooks/useFlowRouter'
import { useLockedFeatureAction } from '@app/common/src/components/LockedFeatureModal/hooks/useLockedFeatureAction'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils'
import { useOnce } from '@dtx-company/true-common/src/hooks/useOnce'

const isInviteModalTabType = (value: string | undefined): value is InviteModalTabType => {
  return value === 'email' || value === 'link'
}

export const useInviteModalQueryParam = (): void => {
  const { isAuthChecked, hasOperation, isAuthenticated } = useAuthState()
  const canViewTeamsPage = hasOperation('view:flowteams')
  const { setOpen: setInviteModalOpen } = useModal(ModalTypes.INVITE_MEMBER_MODAL)
  const router = useFlowRouter()
  const inviteUsers = router.getString('inviteUsers')
  const tabType = isInviteModalTabType(inviteUsers) ? inviteUsers : undefined

  const triggerFlowteamsInviteModal = useCallback(() => {
    if (tabType) {
      setInviteModalOpen({ initialTab: tabType })
    }
  }, [tabType, setInviteModalOpen])

  const openLockedFeatureModal = useLockedFeatureAction({ lockedFeatureName: 'view:flowteams' })

  const triggerOpenModal = (): void => {
    if (canViewTeamsPage) {
      triggerFlowteamsInviteModal()
    } else {
      openLockedFeatureModal({
        onOpen: () => {
          fireAnalyticsEvent('FlowteamsInviteModal_Opened', { tab: tabType })
        }
      })
    }
  }

  useOnce(() => {
    if (
      !isAuthChecked ||
      !isAuthenticated ||
      router.pathname === Routes.SIGN_IN ||
      router.pathname === Routes.SIGN_UP
    )
      return false
    if (tabType) {
      triggerOpenModal()
    }
  })
}
