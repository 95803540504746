import { ElementStyle, setElementStyle } from '../../../utils/setElementStyle'
import { ReactElement, Ref, cloneElement, forwardRef, useRef } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'
import { TransitionProps } from '@mui/material/transitions'
import { getTransitionProps } from '../../../utils/getTransitionProps'
import { useDialogTransitionContext } from './useDialogTransitionContext'
import { useTheme } from '@mui/material'
import useForkRef from '@mui/utils/useForkRef'

/**
 * Provides transitions so that when multiple dialogs are open only one is visible
 * at a time.
 *
 * Requires the DialogTransitionProvider as a parent to provide its context.
 * If not provided, this will still transition dialogs but won't have the stacking
 * effects of moving back and forth between them.
 *
 * Based on the Fade transition (default for a Dialog)
 * https://github.com/mui/material-ui/blob/fe6942947bb9eb920b1784b708dbb4ea58405ce3/packages/mui-material/src/Fade/Fade.js
 *
 * @see Dialog
 * @see DialogTransitionProvider
 */
export const DialogTransition = forwardRef(function DialogTransition(
  props: Omit<TransitionProps, 'children'> & {
    children: ReactElement
    uuid: string
  },
  ref: Ref<unknown>
) {
  const dialogTransitionContext = useDialogTransitionContext()

  const theme = useTheme()
  const defaultTimeout = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  }

  const {
    addEndListener,
    appear = true,
    children,
    easing,
    in: inProp,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    style,
    timeout = defaultTimeout,
    uuid,
    ...other
  } = props

  const nodeRef = useRef<HTMLElement>(null)
  const handleRef = useForkRef(
    nodeRef,
    'ref' in children ? (children.ref as Ref<unknown>) : undefined,
    ref
  )

  // Skips/stops any transition and sets the style immediately
  function setImmediateStyle(element: HTMLElement, style: ElementStyle): void {
    element.style.removeProperty('transition')
    setElementStyle(element, style)
  }

  // Sets the css transition and style to animate towards on the next render frame
  function setTransitionStyle(
    mode: 'enter' | 'exit',
    element: HTMLElement,
    newStyle: ElementStyle
  ): Promise<void> {
    return new Promise(resolve => {
      window.requestAnimationFrame(() => {
        const transitionProps = getTransitionProps(
          {
            style: {
              ...style,
              transitionDelay: newStyle.transitionDelay || style?.transitionDelay
            },
            timeout,
            easing
          },
          { mode }
        )
        const transitionKeys = Object.keys(newStyle).filter(key => {
          return key !== 'transitionDelay'
        })
        element.style.webkitTransition = theme.transitions.create(transitionKeys, transitionProps)
        element.style.transition = theme.transitions.create(transitionKeys, transitionProps)

        setElementStyle(element, newStyle)
        resolve()
      })
    })
  }

  function normalizedTransitionCallback(
    callback: (node: HTMLElement, maybeIsAppearing?: boolean) => void
  ): (maybeIsAppearing?: boolean) => void {
    return (maybeIsAppearing?: boolean) => {
      if (callback) {
        const node = nodeRef.current
        if (!node) return

        // only onEnter* provides an isAppearing arg
        if (maybeIsAppearing === undefined) {
          callback(node)
        } else {
          callback(node, maybeIsAppearing)
        }
      }
    }
  }

  const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
    const topElement = dialogTransitionContext?.at(-1)?.element
    if (topElement) {
      // Animate the existing top element to move out of view
      setTransitionStyle('exit', topElement, {
        opacity: '0',
        transform: 'translateX(-36px)',
        visibility: 'hidden'
      })

      // Animate the new dialog to move into view
      setImmediateStyle(node, {
        opacity: '0',
        transform: 'translateX(36px)',
        visibility: 'hidden'
      })
      setTransitionStyle('enter', node, {
        opacity: '1',
        transform: 'translateX(0)',
        transitionDelay: '150ms',
        visibility: 'visible'
      })
    } else {
      // Animate an initial dialog into view
      setImmediateStyle(node, {
        opacity: '0',
        transform: 'translateY(36px)',
        visibility: 'hidden'
      })
      setTransitionStyle('enter', node, {
        opacity: '1',
        transform: 'none',
        visibility: 'visible'
      })
    }

    // Register this dialog as the new top dialog
    dialogTransitionContext?.add({ id: uuid, element: node })

    if (onEnter) {
      onEnter(node, Boolean(isAppearing))
    }
  })

  const handleEntering = normalizedTransitionCallback((node, isAppearing) => {
    if (onEntering) {
      onEntering(node, Boolean(isAppearing))
    }
  })

  const handleEntered = normalizedTransitionCallback((node, isAppearing) => {
    if (onEntered) {
      onEntered(node, Boolean(isAppearing))
    }
  })

  const handleExit = normalizedTransitionCallback(node => {
    // Remove it so it's no longer the top element
    dialogTransitionContext?.removeById(uuid)

    const topElement = dialogTransitionContext?.at(-1)?.element
    if (topElement) {
      // Animate this dialog out of view
      setTransitionStyle('exit', node, {
        opacity: '0',
        transform: 'translateX(36px)',
        visibility: 'hidden'
      })

      // Animate the new top element back into view
      setTransitionStyle('enter', topElement, {
        opacity: '1',
        transform: 'none',
        transitionDelay: '150ms',
        visibility: 'visible'
      })
    } else {
      // Animate the final dialog out of view
      setTransitionStyle('exit', node, {
        opacity: '0',
        transform: 'translateY(36px)',
        visibility: 'hidden'
      })
    }

    if (onExit) {
      onExit(node)
    }
  })

  const handleExiting = normalizedTransitionCallback(node => {
    if (onExiting) {
      onExiting(node)
    }
  })

  const handleExited = normalizedTransitionCallback(node => {
    if (onExited) {
      onExited(node)
    }
  })

  const handleAddEndListener = (next: () => void): void => {
    const node = nodeRef.current
    if (!node) return

    if (addEndListener) {
      // Old call signature before `react-transition-group` implemented `nodeRef`
      addEndListener(nodeRef.current, next)
    }
  }

  return (
    <Transition
      appear={appear}
      in={inProp}
      nodeRef={nodeRef}
      onEnter={handleEnter}
      onEntered={handleEntered}
      onEntering={handleEntering}
      onExit={handleExit}
      onExited={handleExited}
      onExiting={handleExiting}
      addEndListener={handleAddEndListener}
      timeout={timeout}
      {...other}
    >
      {(_state: TransitionStatus) => {
        return cloneElement(children, {
          style: {
            opacity: 0,
            transform: 'none',
            ...style,
            ...children.props.style
          },
          ref: handleRef
        })
      }}
    </Transition>
  )
})
