import { Ref } from 'react'
import { SetOptional } from 'type-fest'
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps
} from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = SetOptional<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  label?: string
  inputRef?: Ref<HTMLInputElement>
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  label,
  renderInput,
  inputRef,
  disablePortal = true,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  return (
    <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      {...rest}
      ref={inputRef}
      disablePortal={disablePortal}
      {...(renderInput
        ? { renderInput }
        : { renderInput: params => <MuiTextField label={label} {...params} /> })}
    />
  )
}
