import { forwardRef } from 'react'
import MuiDialogActions, {
  DialogActionsProps as MuiDialogActionsProps
} from '@mui/material/DialogActions'

/**
 *
 * Documentation: [https://mui.com/material-ui/api/dialog-actions]
 */

export type DialogActionsProps = Pick<
  MuiDialogActionsProps,
  'id' | 'children' | 'disableSpacing' | 'ref'
>

export const DialogActions = forwardRef<HTMLDivElement, DialogActionsProps>(
  (props, ref): JSX.Element => (
    <MuiDialogActions
      // Styles inserted here so that they affect all instances imported from `design-system`,
      // but not those imported directly from `@mui`
      sx={theme => ({ padding: theme.spacing(6), paddingTop: 0 })}
      {...props}
      ref={ref}
    />
  )
)

DialogActions.displayName = 'DialogActions'
