import { Box } from '../Box/Box'
import { DialogProps } from './Dialog'
import { FC } from 'react'
import { IconButton } from '../IconButton/IconButton'
import CloseIcon from '@mui/icons-material/Close'

type DialogCloseProps = Pick<DialogProps, 'onClose'>

export const DialogClose: FC<DialogCloseProps> = ({ onClose }) => (
  <Box
    sx={theme => ({
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    })}
  >
    <IconButton
      icon={CloseIcon}
      aria-label="Close"
      variant={'text'}
      onClick={event => onClose?.(event, 'escapeKeyDown')}
    />
  </Box>
)
