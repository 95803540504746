import { ChangeEvent, ComponentProps, FC, ReactNode, forwardRef, useId } from 'react'
import { InputLabel, SelectProps as MuiSelectProps } from '@mui/material'
import { MenuItem, MenuItemProps } from '../../internal-components/MenuItem/MenuItem'
import { Stack } from '../Stack/Stack'
import { TextField, TextFieldProps } from '../TextField/TextField'
import { variants } from '../Typography/Typography.overrides'

export type SelectOptions = {
  label: ReactNode
  value: ComponentProps<typeof MenuItem>['value']
}[]

export type SelectProps = Omit<TextFieldProps, 'endAdornment'> &
  Pick<MuiSelectProps, 'onOpen' | 'onClose'> & {
    options?: SelectOptions
    labelAbove?: boolean
    /** Whether the select is read-only and its value cannot be changed. This has
     * the side effect of keeping the label of the field shrunk if using MUI's default
     * label and not labelAbove */
    readOnly?: boolean
    MenuItemProps?: MenuItemProps
  }

export type SelectOnChangeEvent = ChangeEvent<HTMLInputElement>

const Wrapper: FC<{ children: ReactNode; readOnly?: boolean }> = ({ children, readOnly }) => {
  return (
    <Stack
      gap={2}
      sx={{
        // If select is read-only display not-allowed cursor
        '& .MuiSelect-select': {
          cursor: readOnly ? 'not-allowed' : 'auto'
        }
      }}
    >
      {children}
    </Stack>
  )
}

/**
 * Select is a styled variant of MUI's TextField w/ the "select" property set to true. Options are added as <MenuItem key={} value={} /> children
 *
 * Since this is a TextField that utilizes Select we need to type the event as ChangeEvent<HTMLInputElement> when handling onChange.
 *
 * More info can be found at: https://mui.com/material-ui/react-text-field/
 */
export const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      options,
      label,
      labelAbove,
      hiddenLabel,
      defaultValue = '',
      children,
      readOnly,
      InputProps,
      MenuItemProps,
      InputLabelProps,
      onOpen,
      onClose,
      ...rest
    },
    ref
  ) => {
    const injected = { ...rest, defaultValue, select: true, label }
    const selectId = useId()
    const inputLabelId = `${selectId}-label`
    const selectProps: Partial<MuiSelectProps> = {
      onOpen,
      onClose,
      ...(labelAbove
        ? {
            id: selectId,
            labelId: inputLabelId,
            'aria-label': undefined,
            MenuProps: rest.SelectProps?.MenuProps
          }
        : { MenuProps: rest.SelectProps?.MenuProps }),
      IconComponent: rest.SelectProps?.IconComponent
    }
    const inputProps: Partial<SelectProps['InputProps']> = {
      ...InputProps,
      ...(labelAbove ? { inputProps: { 'aria-label': undefined } } : {}),
      readOnly
    }

    return (
      <Wrapper readOnly={readOnly}>
        {labelAbove && (
          <InputLabel
            sx={{ textAlign: 'left', ...variants.captionMedium }}
            {...InputLabelProps}
            htmlFor={selectId}
            id={inputLabelId}
          >
            {label}
          </InputLabel>
        )}
        <TextField
          hiddenLabel={labelAbove || hiddenLabel}
          {...injected}
          SelectProps={{ ...selectProps }}
          InputProps={{ ...inputProps }}
          ref={ref}
          InputLabelProps={{
            ...InputLabelProps,
            shrink: readOnly ? true : InputLabelProps?.shrink
          }}
        >
          {children ||
            options?.map(({ label, value }, i) => (
              <MenuItem {...MenuItemProps} key={`${value}-${i}`} value={value}>
                {label}
              </MenuItem>
            ))}
        </TextField>
      </Wrapper>
    )
  }
)

Select.displayName = 'Select'
