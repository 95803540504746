import {
  InviteMembersModal,
  InviteModalTabType
} from '@dtx-company/inter-app/src/components/org/members/modals/inviteModal'
import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { NavigationV2 } from './v2/navbar'
import { RootCodeState } from '@dtx-company/inter-app/src/redux/types'
import { useCodeSelector } from '@dtx-company/inter-app/src/hooks/useCodeSelector'
import { useInviteModalQueryParam } from '@app/code/src/hooks/useInviteModalQueryParam'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'
import { useScrollPosition } from '../../../hooks/useScrollPosition'
import { useTrialBannerState } from '@app/code/src/components/Homepage/TrialBanner/useTrialBannerState'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

const StyledHeader = styled.nav`
  grid-area: header;
`
const MobileNavigationV3 = dynamic(() =>
  import('./v3/MobileNavigationV3').then(mod => mod.MobileNavigationV3)
)
const TrialBanner = dynamic(() =>
  import('@app/code/src/components/Homepage/TrialBanner/TrialBanner').then(mod => mod.TrialBanner)
)
// check sign up & sign in onArrival modules on updating auth based routing, move here if req.
export const Header = (): JSX.Element | null => {
  const { hasScrolled } = useScrollPosition()

  const { hideNavbar } = useCodeSelector((state: RootCodeState) => state.settings)
  const { isMobileOrTablet } = useScreenSizeBreakpoints()
  const { displayTrialBanner } = useTrialBannerState()
  const {
    modalOpen: inviteModalOpen,
    setClose: setInviteModalClose,
    props: inviteModalProps
  } = useModal<{ initialTab?: InviteModalTabType }>(ModalTypes.INVITE_MEMBER_MODAL)

  useInviteModalQueryParam()

  if (hideNavbar) return null
  return (
    <StyledHeader role="navigation">
      {displayTrialBanner && <TrialBanner />}
      <NavigationV2 hasScrolled={hasScrolled} />
      {isMobileOrTablet && <MobileNavigationV3 />}
      {inviteModalOpen && (
        <InviteMembersModal
          open={inviteModalOpen}
          onClose={() => setInviteModalClose()}
          onInvite={() => setInviteModalClose()}
          inviteModalProps={inviteModalProps}
        />
      )}
    </StyledHeader>
  )
}
