import { AUTHENTICATION_V2_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { OrgAccountType } from '@dtx-company/true-common/src/types/org'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { getPlanTypeName } from '@dtx-company/app/shared/common/src/utils/getPlanTypeName'

export const baseUri = AUTHENTICATION_V2_ENDPOINT

export const orgEndPoints = {
  profiles: baseUri + '/profiles',
  members: baseUri + '/org-members',
  memberships: baseUri + '/org-memberships',
  orgExtras: baseUri + '/org-extras',
  orgCollaborators: baseUri + '/collaborators',
  teams: baseUri + '/teams',
  teamMembers: baseUri + '/team-members',
  invitations: baseUri + '/invitations',
  acceptInvitation: baseUri + '/accept-invitations',
  assetPermissions: baseUri + '/asset-permissions',
  org: baseUri + '/org',
  getInviteDataV2: baseUri + '/v2/invitations',
  acceptInvitationV2: baseUri + '/v2/accept-invitation',
  invitationLink: baseUri + '/invitation-link',
  acceptInvitationLinkV2: baseUri + '/v2/accept-invitation-link'
}

export const numUsersPerPage = 25
export const numTeamsPerPage = 25

export const roles = [
  {
    label: `${getPlanTypeName(PlanType.PRO_PLUS_ORG)} (3-5 seats)`,
    value: OrgAccountType.PRO_PLUS
  },
  {
    label: `${getPlanTypeName(PlanType.PRO_FLEX)} (5-10 seats)`,
    value: OrgAccountType.PRO_FLEX
  },
  {
    label: `${getPlanTypeName(PlanType.ENTERPRISE)} (10+ seats)`,
    value: OrgAccountType.ENTERPRISE
  }
]
